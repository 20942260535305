<template>
    <h2> Privacy Policy </h2>
    <div>
        <p> Elkhorn Valley Family Medicine is committed to upholding the security and confidentiality of personal health information that
            you provide to us. We do not sell or share customer information with any marketing groups. We carefully manage the sharing of
            information to safeguard your privacy. </p>

        <p> We are disclosing this policy as required by federal and state regulations.</p>

        <p> Information we may collect can be categorized as follows: </p>
            <ol>
                <li> Information we receive from you on intake information </li>
                <li> Information we may receive from your insurance companies </li>
                <li> Information we may receive from other healthcare providers </li>
            </ol>

        <p> We may share information with your insurance companies and those who help with claims processing and/or data handling,
            to aid in providing services to you. </p>

        <p>We may share information with other health service offices that may be involved with your treatment plan, i.e., your primary care
            physician or referring physician. This may also include assistants to surgery, anesthesiologists, and healthcare facilities. </p>

        <p>We may disclose any information we collect when permitted or required by law. This may include but is not limited to, disclosures
            related to a court subpoena or similar requests, fraud investigations and an audit or security examination.</p>

        <p>It is very important that you help us keep your account up to date and your information current. You must protect yourself by using
            caution when disclosing personal information such as social security numbers, account numbers, and pin numbers.</p>

        <p>We will use physical, electronic and procedural safeguards that comply with federal and state standard to protect your personal
            health information. </p>

    </div>
</template>

<script>
export default {
    name: "Privacy Policy"
}
</script>

<style scoped>
    div {
        width: 70%;
        margin: auto;
        text-align: left;
    }

</style>